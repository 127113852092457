<!-- 商品管理 -->
<template>
    <div>
        <div class="page-header">商品管理</div>
        <div class="flex">
            <div class="flex-1 block" style="overflow: auto;margin: 20px;background: white;">
                <div class="header flex">
                    <div>
                        <span>分类：</span>
                        <el-cascader :options="cats" :show-all-levels="false"
                            :props="{value:'id',label:'name',emitPath:false}" v-model="query.cat"></el-cascader>
                    </div>
                    <div>
                        <span>风格：</span>
                        <el-select v-model="query.style">
                            <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <span>上架状态：</span>
                        <el-select v-model="query.onSale">
                            <el-option label="已上架" value="1"></el-option>
                            <el-option label="未上架" value="2"></el-option>
                        </el-select>
                    </div>
                    <el-button type="warning">查询</el-button>
                </div>
                <div>
                    <el-table :data="rows" style="width: 100%" class="table">
                        <el-table-column label="产品" width="330">
                            <template slot-scope="scope">
                                <div class="flex flex-align-center">
                                    <img :src="scope.row.img" style="width: 120px;height: 120px;">
                                    <div style="text-align: left;margin-left: 10px;"><b>{{scope.row.name}}</b></div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="价格">
                        </el-table-column>
                        <el-table-column prop="onSale" label="是否上架">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.onSale" active-color="#CC995A" inactive-color="#4D4D4D">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="stockCount" sortable label="库存数量">
                        </el-table-column>
                        <el-table-column prop="pv" sortable label="浏览量">
                        </el-table-column>
                        <el-table-column prop="saleCount" sortable label="累计销量">
                        </el-table-column>
                        <el-table-column prop="saleCount30" label="30天销量">
                        </el-table-column>
                    </el-table>
                </div>
                <div style="text-align: right;">
                    <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible">

        </el-dialog>
    </div>
</template>

<script>
    import { getCats, getStyles } from '@/service/item';
    import { fetchTree } from '@/util'
    export default {
        components: {

        },
        async created() {
            this.cats = fetchTree(await getCats(), 0, { parent: 'parentId' });
            this.styles = await getStyles();
            
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                cats: [],
                styles: [],
                query: {
                    cat: null,
                    style: null,
                    onSale: 0
                },
                rows: [
                    { img: require('@/assets/images/sale/mall/位图.png'), name: '北欧实木床双人床主卧现代简约公主床有温馨感', price: 100, onSale: 0, stockCount: 122, pv: 200, saleCount: 130, saleCount30: 40 },
                    { img: require('@/assets/images/sale/mall/位图.png'), name: '北欧实木床双人床主卧现代简约公主床有温馨感', price: 100, onSale: 0, stockCount: 122, pv: 200, saleCount: 130, saleCount30: 40 },
                    { img: require('@/assets/images/sale/mall/位图.png'), name: '北欧实木床双人床主卧现代简约公主床有温馨感', price: 100, onSale: 0, stockCount: 122, pv: 200, saleCount: 130, saleCount30: 40 },
                    { img: require('@/assets/images/sale/mall/位图.png'), name: '北欧实木床双人床主卧现代简约公主床有温馨感', price: 100, onSale: 0, stockCount: 122, pv: 200, saleCount: 130, saleCount30: 40 },
                    { img: require('@/assets/images/sale/mall/位图.png'), name: '北欧实木床双人床主卧现代简约公主床有温馨感', price: 100, onSale: 0, stockCount: 122, pv: 200, saleCount: 130, saleCount30: 40 }
                ],
                total: 100
            }
        },
        computed: {
            selectedRowIds: function () {
                let ids = this.$refs._table.store.states.selection.reduce((pre, cur) => {
                    pre.push(cur.id)
                    return pre;
                }, []);
                return ids;
            }
        },
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            refreshRows() {
                // getMembersByDept(this.$store.state.loginUser.comp.id, this.dept_id).then(u=>{
                //     this.rows=u.list;
                //     this.total=u.total;
                // }).catch(err => {
                //     this.$message.error(err.message);
                // });
                
            },
            addShop() {

            },
            // 批量删除用户
            delRows() {
                if (this.selectedRowIds.length > 0) {
                    //     delUserByIds(this.selectedUserIds).then(()=>{
                    //         this.$message.success('删除成功');
                    //         this.refreshMembers();
                    //     }).catch(err => {
                    //     this.$message.error(err.message);
                    // });
                }
            },
            // 编辑
            popEditRow(row) {
                // this.$router.push('/contact_edit_user/'+row.id);
                // this.isEditingUser=true;
                // this.curEditUserId=row.id;
                // this.popType=2;
                this.popTitle = '编辑' + row.name;
                this.popVisible = true;
            },
            // 用户保存成功
            // onUserSaved(){
            //     this.popVisible=false;
            //     this.refreshMembers();
            // },
        }
    }
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped>
    .header {
        background: rgba(245, 245, 245, 1);
    }

    .header>*:not(:last-child) {
        margin-right: 15px;
    }
</style>